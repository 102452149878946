<template>
  <div>
    <LoadingOverlay v-if="isFetchingFilter" />
    <CModal
      :title="modalTitle"
      :color="modalColor"
      :show.sync="isShowPopup"
      :closeOnBackdrop="false"
      :size="`lg`"
    >
      <template #header>
        <h6>{{ modalTitle }}</h6>
        <CButtonClose @click="popupModalCallBack(false)" />
      </template>
      <template #footer>
        <CButton
          form="shortlist"
          color="secondary"
          @click="popupModalCallBack(false)"
          >Cancel</CButton
        >
        <CButton form="shortlist" :color="modalColor" type="Submit"
          >Confirm</CButton
        >
      </template>
      <div>
        <ValidationObserver ref="shortlist" v-slot="{ handleSubmit }">
          <form id="shortlist" @submit.prevent="handleSubmit(onSubmit)">
            <CRow>
              <CCol lg="7">
                <CRow class="mb-3">
                  <label class="required col-lg-12">Select Job</label>
                  <div class="col-lg-12">
                    <ValidationProvider
                      ref="jobId"
                      :rules="{ required: true }"
                      v-slot="{ errors }"
                    >
                      <Select
                        name="job_id"
                        :value="candidate.job_id"
                        @input="handleChangeSelect"
                        :options="
                          options && options['jobs'] ? options['jobs'] : []
                        "
                        :error="errors[0]"
                        :clearable="false"
                      />
                    </ValidationProvider>
                  </div>
                </CRow>
              </CCol>
              <CCol lg="5">
                <CRow class="mb-3">
                  <label class="required col-lg-12">Action</label>
                  <div class="col-lg-12">
                    <ValidationProvider
                      ref="action"
                      :rules="{ required: true }"
                      v-slot="{ errors }"
                    >
                      <Select
                        name="action_id"
                        :value="candidate.action_id"
                        @input="handleChangeSelect"
                        :options="
                          options && options['actionOptions']
                              ?  options['actionOptions']
                              : []
                        "
                        :error="errors[0]"
                        :clearable="false"
                      />
                    </ValidationProvider>
                  </div>
                </CRow>
              </CCol>
            </CRow>
            <CRow>
              <CCol md="7">
                <CRow class="mb-3">
                  <label class="col-lg-12">Comments</label>
                  <div class="col-lg-12">
                    <TextInput
                      name="comments"
                      :value="candidate.comments"
                      label="comments"
                      @input="handleInput"
                    ></TextInput>
                  </div>
                </CRow>
              </CCol>
            </CRow>
          </form>
        </ValidationObserver>
      </div>
    </CModal>
  </div>
</template>
<script>
import Vue from "vue";
import { mapGetters, mapActions } from "vuex";
import Select from "@/components/reusable/Fields/Select";
import { extend } from "vee-validate";
import { required } from "vee-validate/dist/rules";
import TextInput from "@/components/reusable/Fields/TextInput";
extend("required", { ...required, message: "This field is required" });
export default {
  props: ["modalTitle", "modalColor", "isShowPopup", "candidateID"],
  data() {
    return {
      candidate: {},
      payload: {},
      actions: [
        {
          label: "Hospital Shortlist",
          code: { update_status: 67, update_sub_status: 41 },
        },
        {
          label: "Shortlist",
          code: { update_status: 68, update_sub_status: 41 },
        },
      ],
      UKMainActions: [
        {
          label: "Shortlist",
          code: { update_status: 68, update_sub_status: 41 },
        },
      ],
      jobSupplierActions: [
        {
          label: "Hospital Shortlist",
          code: { update_status: 67, update_sub_status: 41 },
        },
      ],
      candidateSupplierActions: [
        {
          label: "Agency Matched",
          code: { update_status: 75, update_sub_status: 41 },
        },
      ],
    };
  },
  components: {
    Select,
    TextInput,
  },
  computed: {
    ...mapGetters(["isFetchingFilter", "getCandidateJobs","isUKMainAgency", "isJobSupplierFromAccessToken", "isCandidateSupplierFromAccessToken"]),
    options() {
      return {
        jobs: this.getCandidateJobs || [],
        actionOptions: !this.isUKMainAgency && !this.isCandidateSupplierFromAccessToken && !this.isJobSupplierFromAccessToken
          ? this.actions
          : this.isUKMainAgency
            ? this.UKMainActions
            : this.isCandidateSupplierFromAccessToken
              ? this.candidateSupplierActions
              : this.isJobSupplierFromAccessToken
                ?  this.jobSupplierActions
                : []
      };
    },
  },
  methods: {
    ...mapActions(["fetchAllJobsByShortlistProcess"]),
    popupModalCallBack(action, data) {
      this.$emit("modalCallBack", action, data);
    },
    handleInput(name, value) {
      Vue.set(this.candidate, name, value);
      Vue.set(this.payload, name, value);
    },
    handleChangeSelect(name, value) {
      Vue.set(this.candidate, name, value);
      this.payload = {
        ...this.payload,
        [name]: value ? value.id || value?.code || value : null,
      };
    },
    async onSubmit() {
      const isValid = await this.$refs.shortlist.validate();
      if (!isValid) {
        this.showToast({
          class: "bg-danger text-white",
          message: "please fill mandatory fields!",
        });
        return;
      }
      const finalPayload = {
        payloadData: {
          job_id: this.payload?.job_id,
          candidate_uid: this.candidateID,
          payload: { comments: this.payload?.comments },
        },
        updateStatusSubStatus: this.payload?.action_id,
      };
      this.popupModalCallBack(true, finalPayload);
    },
  },
  mounted() {
    this.fetchAllJobsByShortlistProcess(this.candidateID);
  },
};
</script>