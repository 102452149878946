import { render, staticRenderFns } from "./DetailsSection.vue?vue&type=template&id=f78ef6c8"
import script from "./DetailsSection.vue?vue&type=script&lang=js"
export * from "./DetailsSection.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports